<template>
  <form class="d-flex flex-column align-content-center align-items-center">
    <p-select v-model:value="state.data.kind" class="mb-3 w-100" label="Выбор услуги" :options="workTypes"
              :error="v$.kind.$errors" required/>

    <p-input class="w-100" label="Как мы можем к вам обращаться" v-model:value="state.data.name" :error="v$.name.$errors"
             required/>

    <p-input class="w-100" label="Контактный телефон" v-model:value="state.data.phone" :error="v$.phone.$errors"
             maska="+7 (###) ###-##-##" required/>

    <p-input class="w-100" label="Адрес оказания услуги" v-model:value="state.data.address" :error="v$.address.$errors"
             required/>

    <p-input class="w-100" label="Дополнительные пожелания" textarea v-model:value="state.data.comment"/>

    <div class="form-check mb-3">
      <input class="form-check-input" type="checkbox" v-model="state.acceptTerms" id="flexCheckDefault">
      <label class="form-check-label" for="flexCheckDefault">
        Настоящим подтверждаю, что я ознакомлен и согласен с <a href="/policy.pdf" target="_blank" class="text-decoration-underline">Политикой
        конфиденциальности</a>, а также даю согласие на обработку своих персональных данных
      </label>
    </div>
    <button class="btn btn-dark px-5 text-nowrap py-2" :disabled="!requiredFilled || !state.acceptTerms"
            @click.prevent="submit">
      Отправить
    </button>
    <span class="text-center text-success fw-bold mt-3" v-if="state.submitted">Благодарим за заявку. Наш оператор свяжется с вами в ближайшее время.</span>
    <p-server-error :error="serverError"/>
  </form>
</template>

<script setup>
import api from '@/api'
import urls from '@/api/urls'
import { requiredRu } from '@/common'
import PServerError from '@/components/ui/ServerError'
import form from '@/composables/form'
import { defineEmits, reactive } from 'vue'

const state = reactive({
  data: {
    kind: null,
    name: null,
    phone: null,
    address: null,
    comment: null,
  },
  serverError: null,
  submitted: false,
})

const workTypes = [
  {
    name: 'Поверка счетчика',
    value: 'Поверка счетчика',
  },
  {
    name: 'Замена счетчика',
    value: 'Замена счетчика',
  },
  {
    name: 'Установка счетчика',
    value: 'Установка счетчика',
  },
]

const emit = defineEmits(['created'])

const {
  v$,
  requiredFilled,
  serverError,
  submit,
} = form(state.data, {
  kind: { requiredRu },
  name: { requiredRu },
  phone: { requiredRu },
  address: { requiredRu },
}, async () => {
  await api.post(urls.TICKETS.NEW_USER, state.data)
  state.data.kind = null
  state.data.name = null
  state.data.phone = null
  state.data.address = null
  state.data.comment = null
  state.submitted = true
  emit('created')
})
</script>

<style scoped>

</style>
